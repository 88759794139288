import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import AppFooter from '@/components/App/AppFooter';
import { MetaTagsEnum } from '@/src/enums/meta-tags.enum';
import CustomHead from '@/components/customHead';
import AppBrands from '@/components/App/AppBrand';
import AppFreeBanner from '@/components/App/AppFreeBanner';
import HowHoloWork from '@/components/App/AppHowHoloWork';
import AppRatings from '@/components/App/AppRatings';
import AppMortgageBanks from '@/components/App/MortgageCalculator/AppMortgageBanks';
import AppNavigation from '@/components/App/Navigation/AppNavigation';
import WhyHolo from '@/components/App/WhyHolo';
import { Calculator } from '@/components/Calculator/Calculator';
import MegaHeader from '@/components/Mega/MegaHeader';

function Index() {
  const { i18n, t } = useTranslation('app-common');
  return (
    <>
      <CustomHead
        title={t('Meta.Main.Title')}
        description={t('Meta.Main.Description')}
        image={MetaTagsEnum.IMG_URL}
        url="https://www.useholo.com/"
        hasFBDomainVerification
      />

      <div dir={i18n.dir()}>
        <AppNavigation />
        {/* <AppHeader /> */}
        <MegaHeader isLandingPage />
        <WhyHolo />
        <Calculator />
        <AppMortgageBanks />
        <HowHoloWork />
        <AppFreeBanner />
        <AppRatings localePath="home" />
        <AppBrands />
        <AppFooter display="block" />
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  const translations = await serverSideTranslations(
    locale,
    ['app-common', 'app-home', 'calculator', 'app-calculator', 'home', 'mega'],
    null,
    ['en', 'ar'],
  );

  return {
    props: {
      ...translations,
    },
  };
}

export default Index;
