import React from 'react';
import Image from 'next/image';

import { useTranslation } from 'next-i18next';
import HoloText from '@/components/common/Typography/HoloText';
import Fab from '/public/assets/banks/fab.png';
import Adcb from '/public/assets/banks/adcb.png';
import Mashreq from '/public/assets/banks/mashreq.png';
import Enbd from '/public/assets/banks/enbd.png';
import Adib from '/public/assets/banks/adib.png';
import Hsbc from '/public/assets/banks/hsbc.png';

const AppMortgageBanks = () => {
  const { t } = useTranslation('app-calculator');

  return (
    <section id="mortgage-calculator" className="bg-white px-4 py-24">
      <div>
        <HoloText
          extraClasses="text-center"
          tag="h5"
          text={t('BestBanksInUAE')}
        />
        <div className="mt-[25px] flex flex-wrap items-center justify-center gap-4 sm:gap-6 md:gap-12">
          {[Fab, Adcb, Mashreq, Enbd].map((e, idx) => {
            return (
              <div
                key={idx}
                className="flex h-[90px] w-[90px] scale-110  items-center justify-center sm:h-[100px] sm:w-[100px]"
              >
                <Image
                  alt=""
                  src={e}
                  style={{
                    filter: 'grayscale(100%)',
                  }}
                />
              </div>
            );
          })}
          <div className="flex h-[90px] w-[90px] scale-150  items-center justify-center sm:h-[100px] sm:w-[100px]">
            <Image
              alt=""
              src={Adib}
              style={{
                filter: 'grayscale(100%)',
              }}
            />
          </div>
          <div className="flex h-[90px] w-[90px] scale-75 items-center justify-center sm:h-[100px] sm:w-[100px]">
            <Image
              alt=""
              src={Hsbc}
              style={{
                filter: 'grayscale(100%)',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppMortgageBanks;
