import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import FastImage from '@/src/assets/why-holo/fast.png';
import PersonalImage from '@/src/assets/why-holo/personal.png';
import TransparentImage from '@/src/assets/why-holo/transpernt.png';
import HoloText from '../../common/Typography/HoloText';

const content = [
  {
    Image: FastImage,
    title: 'Slugs.Fast.title',
    description: 'Slugs.Fast.description',
  },
  {
    Image: TransparentImage,
    title: 'Slugs.Personal.title',
    description: 'Slugs.Personal.description',
  },
  {
    Image: PersonalImage,
    title: 'Slugs.Transparent.title',
    description: 'Slugs.Transparent.description',
  },
];

const WhyHolo = () => {
  const { t } = useTranslation('app-home', { keyPrefix: 'WhyHolo' });

  return (
    <section className="bg-white">
      <div className="m-auto flex min-h-[788px] w-full max-w-6xl  flex-col items-center gap-12 px-6 py-6 pb-[68px] pt-12 md:min-h-[525px] md:gap-[92px] md:py-[96px] xl:px-[0px]">
        <div className="flex flex-col items-center">
          <HoloText tag="h1" extraClasses="!text-4xl" text={t('TitleMsg')} />
          <HoloText
            tag="p"
            extraClasses="text-center text-natural-darkGray-2 mt-3"
            text={t('SubTitleMsg')}
          />
        </div>
        <div className="flex flex-col gap-6 md:flex-row">
          {content.map((e) => {
            return (
              <div key={e.title} className="flex flex-col items-center">
                <Image src={e.Image} height={64} width={64} alt="" />
                <HoloText
                  tag="h3"
                  extraClasses="text-center mt-4 mb-2"
                  text={t(e.title)}
                />
                <HoloText
                  flavour="regular"
                  tag="p"
                  extraClasses="text-center text-natural-darkGray-2"
                  text={t(e.description)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default WhyHolo;
