import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import HoloText from '../common/Typography/HoloText';
import Step1 from '../../assets/how-holo-work/step-1.png';
import Step2 from '../../assets/how-holo-work/step-2.png';
import Step3 from '../../assets/how-holo-work/step-3.png';

const content = [
  {
    title: 'steps.step1.title',
    subTitle: 'steps.step1.subTitle',
    content: 'steps.step1.content',
    image: Step1,
    imagePosition:
      'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-[2]',
  },
  {
    title: 'steps.step2.title',
    subTitle: 'steps.step2.subTitle',
    content: 'steps.step2.content',
    imagePosition: '-right-5 -bottom-5 scale-150',
    image: Step2,
  },
  {
    title: 'steps.step3.title',
    subTitle: 'steps.step3.subTitle',
    content: 'steps.step3.content',
    image: Step3,
    imagePosition: '-right-10 top-[50%] -translate-y-1/2 scale-150',
  },
];

const HowHoloWork = () => {
  const { t } = useTranslation('app-home', { keyPrefix: 'HowHoloWork' });

  return (
    <section className="bg-black">
      <div className="m-auto min-h-[788px] max-w-6xl md:min-h-[625px]">
        <div className="flex w-full flex-col items-center gap-12 px-6 py-6 pb-[68px] pt-12 md:gap-[92px] md:py-[96px] xl:px-[0]">
          <div className="flex flex-col items-center">
            <HoloText
              tag="h1"
              text={t('title')}
              extraClasses="text-white !text-4xl md:!text-5xl"
            />
            <HoloText
              tag="p"
              flavour="medium"
              extraClasses="text-center text-natural-gray-1 mt-3"
              text={t('SubTitleMsg')}
            />
          </div>
          <div className="flex  flex-col items-center gap-24 ">
            {content.map((step, idx) => (
              <div
                key={step.title}
                className={`flex  w-full max-w-[1127px] flex-col items-center gap-6 md:justify-around md:gap-[20px] lg:flex-row lg:gap-[120px] ${
                  idx === 1 && 'lg:flex-row-reverse'
                }`}
              >
                <div className=" w-full max-w-[456px]">
                  <Image
                    alt={step.title}
                    className=" rounded-3xl"
                    src={step.image}
                  />
                </div>
                <div>
                  <div className="flex max-w-[551px] flex-col justify-center">
                    <HoloText
                      text={t(step.title)}
                      tag="h2"
                      flavour="large_semiBold"
                      extraClasses="text-accent"
                    />
                    <HoloText
                      extraClasses="text-white mt-2"
                      text={t(step.subTitle)}
                      tag="h3"
                    />
                    <HoloText
                      extraClasses="text-natural-gray-1 mt-8 whitespace-pre-line"
                      text={t(step.content)}
                      tag="p"
                      flavour="medium"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowHoloWork;
