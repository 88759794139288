import React from 'react';

export const highlightWords = (
  text: string,
  words: string[],
  highlightType: 'bold' | 'gradient' | 'large',
): JSX.Element => {
  const escapedWords = words.map((word) =>
    word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
  );
  const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');
  const highlightClass =
    highlightType === 'gradient'
      ? 'new-colorful-gradient-text-background'
      : highlightType === 'large'
        ? 'text-lg font-bold md:text-4xl'
        : 'font-bold';

  return (
    <>
      {text.split(regex).map((part, index) =>
        words.some((word) => word.toLowerCase() === part.toLowerCase()) ? (
          <span key={index} className={highlightClass}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};
